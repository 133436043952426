<template>
  <div class="mobile-view">
    <h1>智能聊天机器人</h1>
    <button style="margin-top: 20px; width: 80%; height: 60px; font-size: 24px;" @click="startRecording">开始提问</button>
    <p style="margin-top: 20px;">录音状态: {{ recordingStatus }}</p>
    <p style="margin-top: 20px;">{{ recordContent }}</p>
  </div>
  <div>
    <!-- <Live2DWidget /> -->
  </div>

</template>

<script setup>
import { ref } from 'vue';
import WebAudioSpeechRecognizer from "@/utils/webaudiospeechrecognizer";
import apiClient from '@/utils/axios';
import { fetchEventSource } from "@microsoft/fetch-event-source";
import Live2DWidget from '@/components/Live2DWidget.vue';

class FatalError extends Error { }

// 定义状态变量
const recordingStatus = ref('未开始');
const recordContent = ref('[无数据]'); // 录制的内容
const messageId = ref(''); // 消息ID
const llmContent = ref(''); // 大模型回复

const webAudioSpeechRecognizer = new WebAudioSpeechRecognizer({ appid: "abc", secretid: "dce" }, true);
// 开始识别的时候
webAudioSpeechRecognizer.OnRecognitionStart = (res) => {
  recordingStatus.value = '开始';
}
// 识别结果发生变化的时候
webAudioSpeechRecognizer.OnRecognitionResultChange = (res) => {
  console.log("识别结果发生变化了:", res);
  recordContent.value = res;
}
// 识别结束的时候
webAudioSpeechRecognizer.OnRecognitionComplete = (res) => {
  recordingStatus.value = '未开始';
  console.log('识别结束，最后的问题是：', res);
  if (res !== '') {
    startQuestion(res);
  }
}
// 识别失败
webAudioSpeechRecognizer.OnError = (res) => {

}
webAudioSpeechRecognizer.OnRecorderStop = (res) => {

}

// 开始录音按钮
const startRecording = () => {
  webAudioSpeechRecognizer.start()
  // startQuestion("写一个童话话故事，500字左右，世和3岁的小朋友看的")
}

// 修正提问的问题文字
const textCorrection = async (text) => {
  let resultText = '';
  const apiUrl = '/api/llm/bailian/textCorrection';
  try {
    const response = await apiClient.post(apiUrl, {
      prompt: `${text}`
    });
    if (response.status === 200) {
      console.log(response.data);
      if (response.data.code === 0) {
        resultText = response.data.data.output.choices[0].message.content;
      }
    } else {
      console.log("error:", response.statusText);
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
  return resultText;
}

// 开始提问
const startQuestion = async (questionText) => {
  // 纠正的文本
  const correctedText = await textCorrection(questionText);
  console.log('纠正后的文本：', correctedText);
  recordContent.value = correctedText;

  // ask(correctedText);



  // apiClient.post(process.env.VUE_APP_API_BASE_URL + '/api/llm/bailian/ask', {
  //   prompt: correctedText
  // }, {
  //   responseType: 'arraybuffer'
  // })
  //   .then(response => {
  //     // 将返回的音频数据转换为Blob对象
  //     const audioBlob = new Blob([response.data], { type: 'audio/wav' });
  //     // 创建一个URL对象用于播放音频
  //     const audioUrl = URL.createObjectURL(audioBlob);
  //     // 创建一个新的Audio对象并播放音频
  //     const audio = new Audio(audioUrl);
  //     audio.play();
  //   })
  //   .catch(error => {
  //     console.error('请求接口失败:', error);
  //   });


  var myHeaders = new Headers();
  myHeaders.append("Accept", "*/*");
  myHeaders.append("Connection", "keep-alive");
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify({ prompt: correctedText, model: 'qwen-plus' }),
    redirect: 'follow'
  };

  const response = await fetch(process.env.VUE_APP_API_BASE_URL + '/api/llm/bailian/ask', requestOptions);
  const reader = response.body.getReader();

  const audioContext = new (window.AudioContext || window.webkitAudioContext)({ sampleRate: 32000 });
  const sampleRate = audioContext.sampleRate;
  const bitsPerSample = 16;
  const bytesPerSample = bitsPerSample / 8;
  const bufferSize = sampleRate / 2; // 0.5 seconds buffer
  let audioBufferQueue = [];
  let source;
  let isPlaying = false;
  let leftover = new Uint8Array(0);

  async function processBuffer() {
    if (isPlaying || audioBufferQueue.length === 0) return;

    const tmpBufQueue = audioBufferQueue;
    audioBufferQueue = [];
    const totalLength = tmpBufQueue.reduce((acc, chunk) => acc + chunk.length, 0);
    const audioBuffer = audioContext.createBuffer(1, totalLength, sampleRate);
    const combinedArray = new Float32Array(totalLength);

    let offset = 0;
    tmpBufQueue.forEach(chunk => {
      combinedArray.set(chunk, offset);
      offset += chunk.length;
    });

    audioBuffer.copyToChannel(combinedArray, 0);

    source = audioContext.createBufferSource();
    source.buffer = audioBuffer;
    source.connect(audioContext.destination);
    source.onended = () => {
      isPlaying = false;
      if (audioBufferQueue.length > 0) {
        processBuffer();
      }
    };
    source.start();
    isPlaying = true;
  }

  let _true = true
  while (_true) {
    const { done, value } = await reader.read();
    if (done) break;

    const combinedValue = new Uint8Array(leftover.length + value.length);
    combinedValue.set(leftover);
    combinedValue.set(value, leftover.length);

    const byteLength = combinedValue.byteLength;
    const remainder = byteLength % bytesPerSample;
    const validLength = byteLength - remainder;

    const validData = combinedValue.slice(0, validLength);

    // Convert validData to Float32Array assuming it's PCM 16-bit signed
    const float32Array = new Float32Array(validLength / bytesPerSample);
    for (let i = 0; i < float32Array.length; i++) {
      if (i < 100) {
        continue
      }
      let value = 0;
      if (bytesPerSample === 2) {
        value = (validData[i * 2 + 1] << 8) | validData[i * 2];
        if (value >= 32768) value -= 65536; // Convert to signed
      }
      float32Array[i] = value / 32768; // Normalize to [-1, 1]
    }

    audioBufferQueue.push(float32Array);

    // Start processing buffer immediately after data is pushed
    processBuffer();

    // Update leftover data for next iteration
    leftover = combinedValue.slice(validLength);
  }







  // apiClient.post('/api/llm/bailian/ask', {
  //   prompt: correctedText,
  // })
  //   .then(response => {
  //     if (response.status === 200) {
  //       console.log('/api/llm/bailian/ask->', response.data)
  //       if (response.data.code === 0) {
  //         console.log('完成');
  //       }
  //     } else {
  //       console.log("error:", response.statusText);
  //     }
  //   })
  //   .catch(error => {
  //     console.error('Error fetching data:', error);
  //   });



  // 提出问题，流式回复
  // const token = '';
  // const abortController = new AbortController();
  // fetchEventSource(process.env.VUE_APP_API_BASE_URL + "/api/llm/bailian/textGenStream", {
  //   method: "POST",
  //   // 请求头参数
  //   headers: {
  //     "Content-Type": "application/json",
  //     "Authorization": `Bearer ${token}`
  //   },
  //   // 具体传参
  //   body: JSON.stringify({
  //     prompt: correctedText,
  //     messageId: messageId.value,
  //   }),
  //   openWhenHidden: true, // 在调用失败时禁止重复调用
  //   signal: abortController.signal,
  //   onmessage(msg) {
  //     const res = JSON.parse(msg.data);
  //     console.log("message", res);
  //     console.log(res.output.choices[0].message.content);
  //     llmContent.value += res.output.choices[0].message.content;

  //   },
  //   onclose() {
  //     // 正常结束的回调
  //     console.log("close");
  //     abortController.abort();
  //   },
  //   onerror(err) {
  //     console.log("error", err);
  //     try {
  //       // onerror后关闭请求
  //       if (abortController) {
  //         abortController.abort();
  //       }
  //     } finally {
  //       console.log("finally", abortController);
  //     }
  //     if (err instanceof FatalError) {
  //       throw err // rethrow to stop the operation
  //     } else {
  //       // do nothing to automatically retry. You can also
  //       // return a specific retry interval here.
  //     }
  //   }
  // });

  // 将流式回答存入消息，按句号切割消息

  // 语音合成tts

}

const ask = async (correctedText) => {

  var myHeaders = new Headers();
  myHeaders.append("Accept", "*/*");
  myHeaders.append("Connection", "keep-alive");
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify({ prompt: correctedText }),
    redirect: 'follow'
  };

  const response = await fetch(process.env.VUE_APP_API_BASE_URL + '/api/llm/bailian/ask', requestOptions);
  const reader = response.body.getReader();

  const audioContext = new (window.AudioContext || window.webkitAudioContext)();
  const sampleRate = 22050;
  let bufferSize = sampleRate / 2; // Adjust buffer size as needed (0.5 seconds buffer)
  let audioBufferQueue = [];
  let source;
  let isPlaying = false;
  let leftover = new Uint8Array(0);

  function processBuffer() {
    // console.log("processBuffer() ")
    if (isPlaying || !audioBufferQueue.length) return;
    console.log("processBuffer() enter")

    const tmpBufQueue = audioBufferQueue;
    audioBufferQueue = [];
    const totalLength = tmpBufQueue.reduce((acc, chunk) => acc + chunk.length, 0);
    console.log("total length: ", totalLength);
    const audioBuffer = audioContext.createBuffer(1, totalLength, sampleRate);
    const combinedArray = new Float32Array(totalLength);

    let offset = 0;
    while (tmpBufQueue.length) {
      const chunk = tmpBufQueue.shift();
      combinedArray.set(chunk, offset);
      offset += chunk.length;
    }

    audioBuffer.copyToChannel(combinedArray, 0);

    source = audioContext.createBufferSource();
    source.buffer = audioBuffer;
    source.connect(audioContext.destination);
    source.onended = () => {
      isPlaying = false;
      if (audioBufferQueue.length > 0) {
        processBuffer();
      }
    };
    source.start();
    isPlaying = true;
  }

  var cot = true;
  while (cot) {
    const { done, value } = await reader.read();
    if (done) break;

    // Combine leftover with new data
    const combinedValue = new Uint8Array(leftover.length + value.length);
    combinedValue.set(leftover);
    combinedValue.set(value, leftover.length);

    const byteLength = combinedValue.byteLength;
    const remainder = byteLength % 4;
    const validLength = byteLength - remainder;

    // Separate valid data and leftover
    const validData = combinedValue.slice(0, validLength);
    leftover = combinedValue.slice(validLength);

    const float32Array = new Float32Array(validData.buffer);
    audioBufferQueue.push(float32Array);

    // Process buffer if enough data is collected
    processBuffer();
  }


}


</script>

<style scoped>
.mobile-view {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
  font-size: 24px;
  text-align: center;
}

p {
  font-size: 16px;
  text-align: center;
}
</style>