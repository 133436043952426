import axios from 'axios';
import router from '../router'

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 10 * 60 * 1000, // 10分钟
});

apiClient.interceptors.request.use(config => {
  // 在发送请求之前做些什么
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => {
  // 对请求错误做些什么
  return Promise.reject(error);
});

apiClient.interceptors.response.use(res => {
    if (res && res.data && res.data.code === 403) {
        router.replace({ name: 'Login' });
    }
    return res;
})

export default apiClient;