import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// 路由守护
// router.beforeEach((to, from, next) => {
//   const token = localStorage.getItem('token');
//   if (to.name !== 'Login' && !token) {
//     next({ name: 'Login' });
//   } else {
//     next();
//   }
// });

export default router;