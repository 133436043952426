import WebRecorder from "./webrecorder.js";
import { SpeechRecognizer, guid } from "./speechrecognizer.js";

export default class WebAudioSpeechRecognizer {
  constructor(params, isLog) {
    this.params = params;
    this.recorder = null;
    this.speechRecognizer = null;
    this.isCanSendData = false;
    this.isNormalEndStop = false;
    this.audioData = [];
    this.isLog = isLog;
    this.requestId = null;
  }
  start() {
    try {
      this.isLog && console.log('start function is click');
      this.requestId = guid();
      this.recorder = new WebRecorder(this.requestId, this.isLog);
      this.recorder.OnReceivedData = (data) => {
        if (this.isCanSendData) {
          // 重组 Int8Array 到 Int16Array
          const combinedData = new Int16Array(data.length / 2);
          for (let i = 0, j = 0; i < data.length; i += 2, j++) {
            combinedData[j] = (data[i] & 0xff) | ((data[i + 1] & 0xff) << 8);
          }
          this.speechRecognizer && this.speechRecognizer.write(combinedData);
        }
      };
      // 录音失败时
      this.recorder.OnError = (err) => {
        console.log("=OnError=", err)
        this.speechRecognizer && this.speechRecognizer.close();
        this.stop();
        this.OnError(err);
      }
      this.recorder.OnStop = (res) => {
        if (this.speechRecognizer) {
          this.speechRecognizer.stop();
          // this.speechRecognizer = null;
        }
        this.OnRecorderStop(res);
      }
      this.recorder.start();
      if (!this.speechRecognizer) {
        this.speechRecognizer = new SpeechRecognizer(this.params, this.requestId, this.isLog);
      }
      // 开始识别
      this.speechRecognizer.OnRecognitionStart = (res) => {
        this.isLog && console.log('开始识别 this.speechRecognizer.OnRecognitionStart', this.recorder);
        if (this.recorder) { // 录音正常
          this.OnRecognitionStart(res);
          this.isCanSendData = true;
        } else {
          console.log('录音未开启，关闭ws连接');
          this.speechRecognizer && this.speechRecognizer.close();
        }
      };
      // 一句话开始
      this.speechRecognizer.OnSentenceBegin = (res) => {
        this.OnSentenceBegin(res);
      };
      // 识别变化时
      this.speechRecognizer.OnRecognitionResultChange = (res) => {
        this.OnRecognitionResultChange(res);
      };
      // 一句话结束
      this.speechRecognizer.OnSentenceEnd = (res) => {
        this.OnSentenceEnd(res);
      };
      // 识别结束
      this.speechRecognizer.OnRecognitionComplete = (res) => {
        console.log('-识别结束>');
        this.stop();
        this.OnRecognitionComplete(res);
        this.isCanSendData = false;
        this.isNormalEndStop = true;
      };
      // 识别错误
      this.speechRecognizer.OnError = (res) => {
        if (this.speechRecognizer && !this.isNormalEndStop) {
          this.OnError(res);
          console.log('异常错误，非正常退出。', res);
        }
        this.speechRecognizer = null;
        this.recorder && this.recorder.stop();
        this.isCanSendData = false;
      };
      // 建立连接
      this.speechRecognizer.start();
    } catch (e) {
      console.log(e);
    }
  }
  stop() {
    this.isLog && console.log('++ stop function is click ++');
    if (this.recorder) {
      console.log('停止录音');
      this.recorder.stop();
    }
    // if (this.speechRecognizer) {
    //   this.speechRecognizer.stop();
    // }
  }
  destroyStream() {
    this.isLog && console.log('destroyStream function is click', this.recorder);
    if (this.recorder) {
      this.recorder.destroyStream();
    }
  }
  // 开始识别的时候
  OnRecognitionStart(res) { }
  // 一句话开始的时候
  OnSentenceBegin(res) { }
  // 识别结果发生变化的时候
  OnRecognitionResultChange(res) { }
  // 一句话结束的时候
  OnSentenceEnd(res) { }
  // 识别结束的时候
  OnRecognitionComplete(res) { }
  // 识别失败
  OnError(res) { }
  OnRecorderStop(res) { }
}
